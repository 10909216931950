import React from 'react';
import Layout from '../../components/layout';

const ProjectPage = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ fontWeight: 'bold' }}>InfoSec policy</h1>
      <p style={{ marginBottom: '20px' }}> developed a comprehensive Information Security Policy for the Jewish General Hospital, addressing key cybersecurity risks, compliance requirements, and best practices to protect sensitive data and ensure patient trust. 
        The policy aligns with national and international standards to support secure and resilient healthcare operations.</p>
      
      {/* Video Embed */}
      <div style={{ marginBottom: '20px' }}>
        <iframe 
          width="100%" 
          height="400px" 
          src="https://www.youtube.com/embed/aEpKuwjvW0U"
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>

      {/* PDF Embed */}
      <div style={{ height: '800px' }}>
        <iframe 
          src="/InfoSecPolicy-project.pdf" 
          width="100%" 
          height="100%" 
          frameBorder="0"
        >
          This browser does not support PDFs. Please download the PDF to view it: <a href="/my-file.pdf">Download PDF</a>
        </iframe>
      </div>
    </div>
  );
};

export default ProjectPage;
